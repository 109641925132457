import React from "react";
import "./Home.css";
import Carousel from "react-bootstrap/Carousel";
import ramtiaave1 from "./a.jpg"; // Import the local images
import ramtiaave2 from "./a.png";
// import ramtiaave3 from "./b.jpeg";

const Home = () => {
  // Use an array with multiple images
  const images = [ramtiaave1, ramtiaave2];

  return (
    <div>
      <Carousel style={{ marginTop: "1px" }} pause="false">
        {images.map((item, index) => (
          <Carousel.Item key={index} interval={1000}>
            <a

            >
              <div
                style={{
                  backgroundImage: `url(${item})`,
                  height: "600px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative", // Ensure relative positioning for absolute positioning of the caption
                }}
                className="banner"
              >
                
              </div>
            </a>
            
          </Carousel.Item>
        ))}
        
      </Carousel>
      <div
                  style={{
                    position: "absolute",
                    top: "50%", // Center vertically
                    left: "20%", // Center horizontally
                    transform: "translate(-50%, -50%)", // Center precisely
                    textAlign: "center", // Align text to the center
                    color: "black", // Text color
                    fontWeight: "bold", // Make the text bold
                    fontSize: "34px", // Set the font size to 24 pixels (adjust as needed)
                  }}
                >
                        <Carousel.Caption>
                    PK METAL INDUSTRIES
                  </Carousel.Caption>
                  
                </div>

    </div>
  );
};

export default Home;
